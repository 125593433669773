.header-container > header {
  height: 68px;
  padding: 0;
  margin: 0; }
  @media (min-width: 960px) {
    .header-container > header {
      height: 140px;
      padding: 0 154px; } }

.header-container .pointer-transparent {
  pointer-events: none; }

.title {
  white-space: nowrap; }
  @media (min-width: 960px) {
    .title > a:first-child {
      display: inline-block; } }
  .title > a:last-child {
    vertical-align: middle; }
    @media (min-width: 960px) {
      .title > a:last-child {
        position: relative;
        top: 3px; } }
  @media (min-width: 960px) {
    .title ~ div {
      width: auto; } }

.embedded-audio {
  font-size: 12px;
  line-height: 14px;
  text-transform: uppercase;
  color: #00639b; }

.logo {
  display: inline-block;
  height: 48px;
  margin-right: 7px; }
  @media (min-width: 960px) {
    .logo {
      height: 113px; } }

.logo-button {
  cursor: default; }
  @media (min-width: 960px) {
    .logo-button {
      display: block !important;
      overflow: visible; } }
