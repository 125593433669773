@import url("https://fonts.googleapis.com/css?family=Roboto:200,300,400,500,600,700,800,900");
@font-face {
  font-family: 'Roboto-Light';
  src: url("@src/assets/fonts/Roboto-Light.ttf"); }

@font-face {
  font-family: 'Roboto-Regular';
  src: url("@src/assets/fonts/Roboto-Regular.ttf"); }

@font-face {
  font-family: 'Roboto-Medium';
  src: url("@src/assets/fonts/Roboto-Medium.ttf"); }

@font-face {
  font-family: 'Noto-Light';
  src: url("@src/assets/fonts/SourceHanSansSC-Light.otf"); }

@font-face {
  font-family: 'Noto-Regular';
  src: url("@src/assets/fonts/SourceHanSansSC-Regular.otf"); }

@font-face {
  font-family: 'Noto-Medium';
  src: url("@src/assets/fonts/SourceHanSansSC-Medium.otf"); }

body {
  margin: 0;
  padding: 0; }
  body h1, body h2, body h3, body h4 {
    margin-top: 0;
    margin-bottom: 0; }

.container {
  width: 100%; }
  @media (min-width: 960px) {
    .container {
      max-width: 1440px; } }
  @media (min-width: 960px) {
    .container > div, .container > h1, .container > h2, .container > h3, .container > h4 {
      padding: 0 8.33%; } }

.banner-heading {
  font-family: 'Roboto-Light', 'Noto-Light';
  color: #FFFFFF;
  text-align: center; }
  @media (min-width: 960px) {
    .banner-heading {
      font-size: 58px;
      line-height: 74px; } }
  @media (max-width: 959px) {
    .banner-heading {
      font-size: 28px;
      line-height: 34px; } }

.banner-subtext {
  font-family: 'Roboto-Regular', 'Noto-Regular';
  color: #FFFFFF;
  text-align: center; }
  @media (min-width: 960px) {
    .banner-subtext {
      font-size: 24px;
      line-height: 33px; } }

@media (min-width: 960px) {
  .section-margin-top {
    margin-top: 100px; } }

@media (max-width: 959px) {
  .section-margin-top {
    margin-top: 50px; } }

@media (min-width: 960px) {
  .section-margin-bottom {
    margin-bottom: 100px; } }

@media (max-width: 959px) {
  .section-margin-bottom {
    margin-bottom: 50px; } }

@media (min-width: 960px) {
  .section-margin {
    margin-top: 100px;
    margin-bottom: 100px; } }

@media (max-width: 959px) {
  .section-margin {
    margin-top: 50px;
    margin-bottom: 50px; } }

@media (min-width: 960px) {
  .section-padding-top {
    padding-top: 100px; } }

@media (max-width: 959px) {
  .section-padding-top {
    padding-top: 50px; } }

@media (min-width: 960px) {
  .section-padding-bottom {
    padding-bottom: 100px; } }

@media (max-width: 959px) {
  .section-padding-bottom {
    padding-bottom: 50px; } }

@media (min-width: 960px) {
  .section-padding {
    padding-top: 100px;
    padding-bottom: 100px; } }

@media (max-width: 959px) {
  .section-padding {
    padding-top: 50px;
    padding-bottom: 50px; } }

.h2, .h2-margin {
  font-family: 'Roboto-Light', 'Noto-Light';
  color: #4A4A4A;
  text-align: center; }
  @media (min-width: 960px) {
    .h2, .h2-margin {
      font-size: 48px;
      line-height: 58px; } }
  @media (max-width: 959px) {
    .h2, .h2-margin {
      font-size: 22px;
      line-height: 32px; } }

.h3, .h3-margin {
  font-family: 'Roboto-Light', 'Noto-Light';
  color: #4A4A4A;
  text-align: center; }
  @media (min-width: 960px) {
    .h3, .h3-margin {
      font-size: 32px;
      line-height: 48px; } }
  @media (max-width: 959px) {
    .h3, .h3-margin {
      font-size: 16px;
      line-height: 24px; } }

@media (min-width: 960px) {
  .h2-margin, .h3-margin, .h5-margin {
    margin-bottom: 80px; } }

@media (max-width: 959px) {
  .h2-margin, .h3-margin, .h5-margin {
    margin-bottom: 40px; } }

.h4-highlight, .h4-highlight-margin {
  font-family: 'Roboto-Light', 'Noto-Light';
  color: #003D4C; }
  @media (min-width: 960px) {
    .h4-highlight, .h4-highlight-margin {
      font-size: 32px;
      line-height: 38px; } }

.h5, .h5-margin {
  font-family: 'Roboto-Light', 'Noto-Light';
  color: #4A4A4A; }
  @media (min-width: 960px) {
    .h5, .h5-margin {
      font-size: 22px;
      line-height: 29px; } }

.regular, .regular-margin {
  font-family: 'Roboto-Regular', 'Noto-Regular';
  color: #4A4A4A; }
  @media (min-width: 960px) {
    .regular, .regular-margin {
      font-size: 18px; } }
  @media (max-width: 959px) {
    .regular, .regular-margin {
      font-size: 12px;
      line-height: 22px; } }

@media (min-width: 960px) {
  .h4-highlight-margin, .regular-margin {
    margin-bottom: 40px; } }

@media (max-width: 959px) {
  .h4-highlight-margin, .regular-margin {
    margin-bottom: 20px; } }

.link, .button, .button-white {
  font-family: 'Roboto-Medium', 'Noto-Medium';
  color: #00A4E4;
  text-transform: none; }
  @media (min-width: 960px) {
    .link, .button, .button-white {
      font-size: 18px; } }
  @media (max-width: 959px) {
    .link, .button, .button-white {
      font-size: 12px; } }

.button-white {
  color: #FFFFFF; }

.font-light {
  font-family: 'Roboto-Light', 'Noto-Light'; }

.font-regular {
  font-family: 'Roboto-Regular', 'Noto-Regular'; }

.font-medium {
  font-family: 'Roboto-Medium', 'Noto-Medium'; }

.tab {
  color: #4A4A4A;
  text-transform: uppercase; }
  @media (min-width: 960px) {
    .tab {
      font-family: 'Roboto-Medium', 'Noto-Medium';
      font-size: 20px;
      line-height: 38px; } }
  @media (max-width: 959px) {
    .tab {
      font-family: 'Roboto-Regular', 'Noto-Regular';
      font-weight: bold;
      font-size: 12px;
      line-height: 34px; } }

.wrap {
  white-space: pre-wrap; }

/* clears the 'X' from Internet Explorer */
input[type=search]::-ms-clear {
  display: none;
  width: 0;
  height: 0; }

input[type=search]::-ms-reveal {
  display: none;
  width: 0;
  height: 0; }

/* clears the 'X' from Chrome */
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  display: none; }
