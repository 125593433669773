.container {
  width: 100%;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  -ms-flex-align: center;
      align-items: center;
  background-color: #EFF0F1; }

.form {
  width: 100%;
  height: 500px;
  border: 0; }
  @media (min-width: 960px) {
    .form {
      max-width: 1440px; } }
