.container {
  width: 100%;
  background-color: #00a6e7;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 106px 200px;
  grid-template-columns: 106px 200px;
  -ms-grid-rows: 105px 106px;
  grid-template-rows: 105px 106px;
  width: 306px;
  height: 207px;
  position: fixed;
  right: -200px;
  top: 496px;
  z-index: 100;
  -webkit-box-shadow: -8px 0px 8px #00000047;
          box-shadow: -8px 0px 8px #00000047;
  border: 1px solid #ffffff;
  border-right: none;
  border-radius: 8px 0 0 8px;
  display: grid;
  -webkit-transition: right 0.2s ease;
  -o-transition: right 0.2s ease;
  transition: right 0.2s ease; }.container > *:nth-child(1) {
  -ms-grid-row: 1;
  -ms-grid-column: 1; }.container > *:nth-child(2) {
  -ms-grid-row: 1;
  -ms-grid-column: 2; }.container > *:nth-child(3) {
  -ms-grid-row: 2;
  -ms-grid-column: 1; }.container > *:nth-child(4) {
  -ms-grid-row: 2;
  -ms-grid-column: 2; }
  .container .phone-button {
    width: 100%;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
        align-items: center;
    -ms-flex-pack: center;
        justify-content: center;
    border-bottom: 1px solid rgba(255, 255, 255, 0.3); }
  .container .phone-number {
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: left;
        justify-content: left;
    -ms-flex-align: center;
        align-items: center;
    color: white;
    font-family: 'Roboto-medium'; }
  .container .mail-button {
    width: 100%;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
        align-items: center;
    -ms-flex-pack: center;
        justify-content: center; }
  .container .mail-address {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: left;
        justify-content: left;
    -ms-flex-align: center;
        align-items: center;
    color: white;
    font-family: 'Roboto-medium';
    cursor: pointer; }
  .container .icon-style {
    width: 42px; }

.opened {
  right: 0px; }
