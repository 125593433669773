.container {
  width: 100%;
  background-color: #2A292A;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center; }
  .container .content {
    width: 100%; }
    @media (min-width: 960px) {
      .container .content {
        position: relative; } }
    @media (min-width: 960px) {
      .container .content .map-description {
        margin-top: 63px;
        margin-bottom: 65px;
        width: 40%;
        position: relative;
        z-index: 1; } }
    @media (max-width: 959px) {
      .container .content .map-description {
        display: none; } }
    .container .content .map-description .description {
      color: #FFFFFF;
      font-family: 'Roboto-Light', 'Noto-Light'; }
      @media (min-width: 960px) {
        .container .content .map-description .description {
          font-size: 28px;
          line-height: 40px;
          margin-bottom: 50px; } }
      @media (max-width: 959px) {
        .container .content .map-description .description {
          font-size: 18px;
          line-height: 28px;
          margin-bottom: 5px; } }
    .container .content .map-description .legend {
      font-family: 'Roboto-Light', 'Noto-Light';
      color: #FFFFFF; }
      @media (min-width: 960px) {
        .container .content .map-description .legend {
          font-size: 16px;
          line-height: 26px; } }
      @media (max-width: 959px) {
        .container .content .map-description .legend {
          font-size: 14px;
          line-height: 36px; } }
      .container .content .map-description .legend .cross {
        vertical-align: middle; }
        @media (min-width: 960px) {
          .container .content .map-description .legend .cross {
            width: 18px;
            height: 18px;
            margin-right: 10px; } }
        @media (max-width: 959px) {
          .container .content .map-description .legend .cross {
            width: 18px;
            height: 18px;
            margin-right: 10px; } }
    @media (min-width: 960px) {
      .container .content .map {
        position: absolute;
        top: 63px;
        right: 67px;
        width: 52%; } }
    .container .content .map > img {
      width: 100%; }
    .container .content .divider {
      height: 2px;
      width: 100%;
      opacity: 0.41;
      -webkit-box-sizing: border-box;
              box-sizing: border-box;
      border: 1px solid #979797; }
      @media (max-width: 959px) {
        .container .content .divider {
          display: none; } }
    .container .content .links {
      display: -ms-grid;
      display: grid; }
      @media (min-width: 960px) {
        .container .content .links {
          padding: 50px 0px;
              grid-template-areas: "external social";
          -ms-grid-columns: 1fr 1fr;
          grid-template-columns: 1fr 1fr; } }
      @media (max-width: 959px) {
        .container .content .links {
          display: none; } }
      .container .content .links .social {
        grid-area: social; }
        @media (max-width: 959px) {
          .container .content .links .social .contacts {
            font-size: 16px;
            line-height: 36px;
            margin: 13px 30px 30px; } }
        @media (min-width: 960px) {
          .container .content .links .social .contacts {
            font-size: 18px;
            line-height: 36px;
            -webkit-transform: translateX(-50px);
                -ms-transform: translateX(-50px);
                    transform: translateX(-50px);
            margin-bottom: 15px; } }
        @media (max-width: 959px) {
          .container .content .links .social .contacts img {
            margin-right: 14px; } }
        @media (min-width: 960px) {
          .container .content .links .social .contacts img {
            margin-right: 14px; } }
        .container .content .links .social .contacts a, .container .content .links .social .contacts a:hover {
          color: #FFFFFF; }
        @media (min-width: 960px) {
          .container .content .links .social a:not(:last-child) {
            margin-right: 33px; } }
        @media (max-width: 959px) {
          .container .content .links .social a:not(:last-child) {
            margin-right: 19px; } }
        @media (min-width: 960px) {
          .container .content .links .social img {
            width: 27px; } }
        @media (max-width: 959px) {
          .container .content .links .social img {
            width: 22px; } }
      .container .content .links .external {
        grid-area: external;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-direction: column;
            flex-direction: column;
        -ms-flex-align: start;
            align-items: flex-start; }
      @media (min-width: 960px) {
        .container .content .links .social {
    -ms-grid-row: 1;
    -ms-grid-column: 2; }
        .container .content .links .external {
    -ms-grid-row: 1;
    -ms-grid-column: 1; } }
        .container .content .links .external a {
          color: #FFFFFF;
          display: inline-block; }
          @media (min-width: 960px) {
            .container .content .links .external a {
              font-size: 18px;
              line-height: 42px; } }
          @media (max-width: 959px) {
            .container .content .links .external a {
              font-size: 12px;
              line-height: 22px; } }
      .container .content .links .internal {
        display: -ms-grid;
        display: grid;
        grid-area: internal; }
        @media (min-width: 960px) {
          .container .content .links .internal {
            -ms-grid-columns: 1fr 45px 1fr 45px 1fr;
            grid-template-columns: repeat(3, 1fr);
            -ms-grid-rows: auto 38px auto;
            grid-template-rows: repeat(2, auto);
            grid-column-gap: 45px;
            grid-row-gap: 38px; }
          .container .content .links .internal > *:nth-child(1) {
    -ms-grid-row: 1;
    -ms-grid-column: 1; }
          .container .content .links .internal > *:nth-child(2) {
    -ms-grid-row: 1;
    -ms-grid-column: 3; }
          .container .content .links .internal > *:nth-child(3) {
    -ms-grid-row: 1;
    -ms-grid-column: 5; }
          .container .content .links .internal > *:nth-child(4) {
    -ms-grid-row: 3;
    -ms-grid-column: 1; }
          .container .content .links .internal > *:nth-child(5) {
    -ms-grid-row: 3;
    -ms-grid-column: 3; }
          .container .content .links .internal > *:nth-child(6) {
    -ms-grid-row: 3;
    -ms-grid-column: 5; } }
        @media (max-width: 959px) {
          .container .content .links .internal {
                grid-template-areas: 'hardware software' 'about product_design' 'about co_branding' 'about contact_us';
            -ms-grid-columns: 1fr 1fr;
            grid-template-columns: 1fr 1fr;
            -ms-grid-rows: auto auto auto auto;
            grid-template-rows: auto auto auto auto; } }
        .container .content .links .internal .internal-group {
          display: -ms-flexbox;
          display: flex;
          -ms-flex-direction: column;
              flex-direction: column; }
          @media (min-width: 960px) {
            .container .content .links .internal .internal-group {
              min-width: 140px; } }
          @media (max-width: 959px) {
            .container .content .links .internal .internal-group:nth-child(1) {
              grid-area: hardware; }
            .container .content .links .internal .internal-group:nth-child(2) {
              grid-area: software; }
            .container .content .links .internal .internal-group:nth-child(3) {
              grid-area: about; }
            .container .content .links .internal .internal-group:nth-child(4) {
              grid-area: product_design; }
            .container .content .links .internal .internal-group:nth-child(5) {
              grid-area: co_branding; }
            .container .content .links .internal .internal-group:nth-child(6) {
              grid-area: contact_us; } }
          @media (max-width: 959px) {
          .container .content .links .internal .internal-group:nth-child(1) {
    -ms-grid-row: 1;
    -ms-grid-column: 1; }
          .container .content .links .internal .internal-group:nth-child(2) {
    -ms-grid-row: 1;
    -ms-grid-column: 2; }
          .container .content .links .internal .internal-group:nth-child(3) {
    -ms-grid-row: 2;
    -ms-grid-row-span: 3;
    -ms-grid-column: 1; }
          .container .content .links .internal .internal-group:nth-child(4) {
    -ms-grid-row: 2;
    -ms-grid-column: 2; }
          .container .content .links .internal .internal-group:nth-child(5) {
    -ms-grid-row: 3;
    -ms-grid-column: 2; }
          .container .content .links .internal .internal-group:nth-child(6) {
    -ms-grid-row: 4;
    -ms-grid-column: 2; } }
          .container .content .links .internal .internal-group .internal-group-title {
            color: #49CCFF; }
            @media (min-width: 960px) {
              .container .content .links .internal .internal-group .internal-group-title {
                font-size: 18px;
                line-height: 31px; } }
            @media (max-width: 959px) {
              .container .content .links .internal .internal-group .internal-group-title {
                font-size: 14px;
                line-height: 31px; } }
          @media (max-width: 959px) {
            .container .content .links .internal .internal-group .internal-group-title:not(:first-child) {
              margin-top: 13px; } }
          .container .content .links .internal .internal-group .internal-link {
            color: #FFFFFF;
            display: inline-block; }
            @media (min-width: 960px) {
              .container .content .links .internal .internal-group .internal-link {
                font-size: 16px;
                line-height: 44px; } }
            @media (max-width: 959px) {
              .container .content .links .internal .internal-group .internal-link {
                font-size: 12px;
                line-height: 22px; } }
    .container .content .brands {
      display: -ms-grid;
      display: grid;
      width: 100%; }
      .container .content .brands > div {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-pack: center;
            justify-content: center;
        -ms-flex-align: center;
            align-items: center; }
        .container .content .brands > div .akg {
          width: 67px; }
        .container .content .brands > div .kardon {
          width: 136px; }
        .container .content .brands > div .infinity {
          width: 87px; }
        .container .content .brands > div .jbl {
          width: 47px; }
        .container .content .brands > div .laxicon {
          width: 70px; }
        .container .content .brands > div .marko {
          width: 87px; }
        .container .content .brands > div .revel {
          width: 72px; }
      @media (min-width: 960px) {
        .container .content .brands {
          margin: 48px 0px 68px;
          -ms-grid-columns: (1fr)[7];
          grid-template-columns: repeat(7, 1fr);
          -ms-grid-rows: auto;
          grid-template-rows: auto; }
        .container .content .brands > *:nth-child(1) {
    -ms-grid-row: 1;
    -ms-grid-column: 1; }
        .container .content .brands > *:nth-child(2) {
    -ms-grid-row: 1;
    -ms-grid-column: 2; }
        .container .content .brands > *:nth-child(3) {
    -ms-grid-row: 1;
    -ms-grid-column: 3; }
        .container .content .brands > *:nth-child(4) {
    -ms-grid-row: 1;
    -ms-grid-column: 4; }
        .container .content .brands > *:nth-child(5) {
    -ms-grid-row: 1;
    -ms-grid-column: 5; }
        .container .content .brands > *:nth-child(6) {
    -ms-grid-row: 1;
    -ms-grid-column: 6; }
        .container .content .brands > *:nth-child(7) {
    -ms-grid-row: 1;
    -ms-grid-column: 7; } }
      @media (max-width: 959px) {
        .container .content .brands {
          margin: 37px 0px 22px;
          -ms-grid-columns: (1fr)[2];
          grid-template-columns: repeat(2, 1fr);
          -ms-grid-rows: 1fr 21px 1fr 21px 1fr 21px 1fr;
          grid-template-rows: repeat(4, 1fr);
          grid-row-gap: 21px; }
        .container .content .brands > *:nth-child(1) {
    -ms-grid-row: 1;
    -ms-grid-column: 1; }
        .container .content .brands > *:nth-child(2) {
    -ms-grid-row: 1;
    -ms-grid-column: 2; }
        .container .content .brands > *:nth-child(3) {
    -ms-grid-row: 3;
    -ms-grid-column: 1; }
        .container .content .brands > *:nth-child(4) {
    -ms-grid-row: 3;
    -ms-grid-column: 2; }
        .container .content .brands > *:nth-child(5) {
    -ms-grid-row: 5;
    -ms-grid-column: 1; }
        .container .content .brands > *:nth-child(6) {
    -ms-grid-row: 5;
    -ms-grid-column: 2; }
        .container .content .brands > *:nth-child(7) {
    -ms-grid-row: 7;
    -ms-grid-column: 1; }
        .container .content .brands > *:nth-child(8) {
    -ms-grid-row: 7;
    -ms-grid-column: 2; } }
    .container .content .privacy {
      display: -ms-flexbox;
      display: flex;
      font-family: 'Roboto-Light', 'Noto-Light'; }
      @media (min-width: 960px) {
        .container .content .privacy {
          -ms-flex-direction: row;
              flex-direction: row;
          -ms-flex-pack: center;
              justify-content: center;
          font-size: 18px;
          line-height: 21px;
          margin-bottom: 86px;
          margin-top: 40px; } }
      @media (max-width: 959px) {
        .container .content .privacy {
          -ms-flex-direction: column;
              flex-direction: column;
          -ms-flex-align: center;
              align-items: center;
          font-size: 12px;
          line-height: 14px;
          margin-bottom: 25px;
          margin-top: 22px; } }
      .container .content .privacy a, .container .content .privacy span {
        color: #FFFFFF; }
        @media (min-width: 960px) {
          .container .content .privacy a, .container .content .privacy span {
            opacity: 0.47; } }
        @media (max-width: 959px) {
          .container .content .privacy a, .container .content .privacy span {
            opacity: 0.36; } }
      @media (min-width: 960px) {
        .container .content .privacy a:not(:last-child) {
          margin-right: 16px; } }
      @media (max-width: 959px) {
        .container .content .privacy a:not(:last-child) {
          margin-right: 8px; } }
      @media (min-width: 960px) {
        .container .content .privacy a:not(:first-child) {
          margin-left: 16px; } }
      @media (max-width: 959px) {
        .container .content .privacy a:not(:first-child) {
          margin-left: 8px; } }
      @media (min-width: 960px) {
        .container .content .privacy .copyright {
          margin-left: 32px; } }
      @media (max-width: 959px) {
        .container .content .privacy .copyright {
          text-align: center;
          padding: 0 10px; } }
