.container {
  display: -ms-flexbox;
  display: flex; }
  @media (min-width: 960px) {
    .container {
      width: 100%;
      -ms-flex-direction: row;
          flex-direction: row;
      -ms-flex-pack: distribute;
          justify-content: space-around;
      position: relative; } }
  @media (max-width: 959px) {
    .container {
      -ms-flex-direction: column;
          flex-direction: column;
      -ms-flex-item-align: center;
          -ms-grid-row-align: center;
          align-self: center; } }
  .container .line {
    position: absolute;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: #CAC8C8; }
    @media (min-width: 960px) {
      .container .line {
        bottom: -15px; } }
  @media (max-width: 959px) {
    .container .tab {
      position: relative; } }
  .container .tab-title {
    cursor: pointer;
    text-transform: uppercase; }
    @media (min-width: 960px) {
      .container .tab-title {
        padding: 0 28px; } }
  .container .button {
    color: #4A4A4A; }
    @media (max-width: 959px) {
      .container .button {
        font-size: 14px;
        line-height: 38px; } }
  .container .underline {
    background: #00A4E4;
    position: absolute;
    left: 0; }
    @media (min-width: 960px) {
      .container .underline {
        width: 0px;
        height: 7px;
        bottom: -18px;
        will-change: left, width;
        -webkit-transition: left .2s, width .2s;
        -o-transition: left .2s, width .2s;
        transition: left .2s, width .2s; } }
    @media (max-width: 959px) {
      .container .underline {
        height: 3px;
        width: 100%;
        bottom: 0; } }
