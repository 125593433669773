.container {
  width: 100%;
  background-color: #E8F9FF;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  -ms-flex-align: center;
      align-items: center; }
  @media (min-width: 960px) {
    .container {
      padding: 80px 0; } }
  @media (max-width: 959px) {
    .container {
      padding: 26px 0 48px; } }
  .container .title {
    text-align: center; }
    @media (min-width: 960px) {
      .container .title {
        color: #4A4A4A;
        font-size: 48px;
        line-height: 56px;
        margin-bottom: 80px; } }
    @media (max-width: 959px) {
      .container .title {
        color: #2A292A;
        font-size: 24px;
        line-height: 34px;
        margin: 0 30px 23px; } }
  .container .tabs {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: center;
        justify-content: center; }
    @media (min-width: 960px) {
      .container .tabs {
        padding-bottom: 22px; } }
    @media (max-width: 959px) {
      .container .tabs {
        padding-bottom: 36px; } }
    @media (max-width: 959px) {
      .container .tabs > div {
        margin: 0 22px; } }
