.container {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
      flex-direction: row;
  -ms-flex-align: center;
      align-items: center; }
  @media (min-width: 1280px) {
    .container {
      width: 100%;
      -ms-flex-pack: end;
          justify-content: flex-end; } }
  .container > img {
    width: 191px;
    height: 114px;
    display: none; }
    @media (min-width: 1280px) {
      .container > img {
        display: initial; } }
  .container .navigation {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
        flex-direction: column; }
    @media (min-width: 1280px) {
      .container .navigation {
        -ms-flex-direction: row;
            flex-direction: row; } }
    @media (max-width: 1279px) {
      .container .navigation {
        width: 100%;
        padding: 0; } }
    @media (max-width: 1279px) {
      .container .navigation .list-item {
        border-bottom: 1px solid #f4f4f4;
        padding: 0px;
        width: 250px; } }
    @media (min-width: 1280px) {
      .container .navigation .list-item {
        display: list-item;
        width: auto; } }
    .container .navigation .navigation-item {
      height: 57px;
      padding: 14px 16px; }
      @media (max-width: 1279px) {
        .container .navigation .navigation-item {
          -ms-flex-pack: left;
              justify-content: left;
          width: 250px;
          padding: 0px 16px;
          margin: 0px;
          line-height: 43px;
          font-size: 16px !important;
          height: 43px;
          text-align: left; }
          .container .navigation .navigation-item:hover::after {
            width: 0px; } }
      .container .navigation .navigation-item .text {
        font-size: 16px; }
        @media (max-width: 1279px) {
          .container .navigation .navigation-item .text {
            width: 100%;
            display: -ms-flexbox;
            display: flex;
            -ms-flex-pack: justify;
                justify-content: space-between;
            -ms-flex-align: center;
                align-items: center; } }
      .container .navigation .navigation-item .arrow {
        margin-left: 4px; }
        @media (max-width: 1279px) {
          .container .navigation .navigation-item .arrow {
            height: -webkit-fit-content;
            height: -moz-fit-content;
            height: fit-content; } }
      @media (max-width: 1279px) {
        .container .navigation .navigation-item .opened {
          -webkit-transform: rotate(180deg);
              -ms-transform: rotate(180deg);
                  transform: rotate(180deg); } }
  .container .search {
    display: none; }
    @media (min-width: 1280px) {
      .container .search {
        display: initial; } }
    .container .search:hover {
      cursor: pointer; }
    .container .search > img {
      margin: 16px; }

.menu-item {
  color: #0073AE;
  font-size: 16px; }
  @media (min-width: 1280px) {
    .menu-item {
      padding: 0 20px; } }
  @media (max-width: 1279px) {
    .menu-item {
      font-size: 14px;
      padding-left: 0px; } }

@media (max-width: 1279px) {
  .menu-item-container {
    padding-left: 0px; } }

.menu-item:hover {
  color: #0073AE; }
