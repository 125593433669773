.content-container {
  width: 100%;
  display: -ms-grid;
  display: grid; }
  @media (min-width: 960px) {
    .content-container {
      padding-top: 80px; } }
  @media (max-width: 959px) {
    .content-container {
      padding: 0 24px; } }
  @media (min-width: 960px) {
    .content-container > div {
      display: -ms-flexbox;
      display: flex;
      -ms-flex-direction: column;
          flex-direction: column; } }
  .content-container .area-image > div {
    background-size: contain;
    background-repeat: no-repeat; }
    @media (max-width: 959px) {
      .content-container .area-image > div {
        background-position: 50% 50%; } }
  @media (min-width: 960px) {
    .content-container .description {
      font-family: 'Roboto-Light', 'Noto-Light';
      color: #000000;
      font-size: 22px;
      line-height: 32px; } }
  @media (max-width: 959px) {
    .content-container .description {
      font-family: 'Roboto-Regular', 'Noto-Regular';
      color: #4A4A4A;
      font-size: 14px;
      line-height: 22px;
      text-align: center; } }
  @media (min-width: 960px) {
    .content-container .description-small {
      font-family: 'Roboto-Light', 'Noto-Light';
      color: #000000;
      font-size: 18px;
      line-height: 24px; } }
  @media (max-width: 959px) {
    .content-container .description-small {
      font-family: 'Roboto-Regular', 'Noto-Regular';
      color: #4A4A4A;
      font-size: 14px;
      line-height: 22px;
      text-align: center; } }
  .content-container .text {
    color: #000000; }
    @media (min-width: 960px) {
      .content-container .text {
        font-family: 'Roboto-Light', 'Noto-Light';
        font-size: 18px;
        line-height: 26px; } }
    @media (max-width: 959px) {
      .content-container .text {
        grid-area: text;
        font-family: 'Roboto-Regular', 'Noto-Regular';
        font-size: 12px;
        line-height: 26px; } }
  .content-container img {
    width: 100%;
    -o-object-fit: contain;
       object-fit: contain; }

@media (min-width: 960px) {
  .tab1 {
    -ms-grid-columns: 1fr 24px 1fr;
    grid-template-columns: 1fr 1fr;
    -ms-grid-rows: auto;
    grid-template-rows: auto;
        grid-template-areas: "description image";
    grid-row-gap: 57px;
    grid-column-gap: 24px; } }

@media (max-width: 959px) {
  .tab1 {
    -ms-grid-rows: auto 25px auto 25px auto;
    grid-template-rows: auto auto auto;
        grid-template-areas: "image" "description";
    grid-row-gap: 25px;
    padding: 0; } }

.tab1 .area-image {
  grid-area: image; }
  @media (min-width: 960px) {
    .tab1 .area-image {
      padding-top: 20px; } }
  .tab1 .area-image > div {
    width: 100%; }
    @media (min-width: 960px) {
      .tab1 .area-image > div {
        background-position: 0% 50%;
        height: 340px; } }
    @media (max-width: 959px) {
      .tab1 .area-image > div {
        height: 219px; } }

.tab1 .area-description {
  grid-area: description;
  padding: 0 24px;
  font-family: 'Roboto-Light', 'Noto-Light'; }
  @media (min-width: 960px) {
    .tab1 .area-description {
      font-size: 24px;
      line-height: 38px; } }
  @media (max-width: 959px) {
    .tab1 .area-description {
      font-size: 12px; } }

.tab1 .area-download {
  grid-area: download; }

.tab1 ul {
  color: #000000;
  list-style-type: none;
  padding-left: 0px; }
  .tab1 ul li {
    font-family: 'Roboto-Light', 'Noto-Light'; }
    @media (min-width: 960px) {
      .tab1 ul li {
        padding-top: 40px; } }
    @media (max-width: 959px) {
      .tab1 ul li {
        padding-top: 28px; } }

.tab2 * {
  white-space: pre-wrap; }

@media (min-width: 960px) {
  .tab2 {
    -ms-grid-columns: 1fr 24px 1fr;
    grid-template-columns: 1fr 1fr;
    -ms-grid-rows: auto 45px auto;
        grid-template-areas: "image description" "image download";
    grid-row-gap: 45px;
    grid-column-gap: 24px; }
  .tab2 > .area-image {
    -ms-grid-row: 1;
    -ms-grid-row-span: 3;
    -ms-grid-column: 1; }
  .tab2 > .area-description {
    -ms-grid-row: 1;
    -ms-grid-column: 3; }
  .tab1 .area-download {
    -ms-grid-row: 3;
    -ms-grid-column: 3; } }

@media (max-width: 959px) {
  .tab2 {
    -ms-grid-rows: auto 25px auto 25px auto;
    grid-template-rows: auto auto auto;
        grid-template-areas: "image" "description" "download";
    grid-row-gap: 25px; }
  .tab2 > .area-image {
    -ms-grid-row: 1;
    -ms-grid-row-span: 1;
    -ms-grid-column: 1; }
  .tab2 > .area-description {
    -ms-grid-row: 3;
    -ms-grid-column: 1; }
  .tab2 > .area-download {
    -ms-grid-row: 5;
    -ms-grid-column: 1; } }

.tab2 .area-image {
  grid-area: image; }
  .tab2 .area-image > div {
    width: 100%; }
    @media (min-width: 960px) {
      .tab2 .area-image > div {
        background-position: 100% 50%;
        height: 385px; } }
    @media (max-width: 959px) {
      .tab2 .area-image > div {
        height: 220px; } }

.tab2 .area-description {
  grid-area: description;
  font-family: 'Roboto-Light', 'Noto-Light'; }
  @media (min-width: 960px) {
    .tab2 .area-description {
      font-size: 24px;
      line-height: 38px; } }
  @media (max-width: 959px) {
    .tab2 .area-description {
      font-size: 12px; } }

.tab2 .area-download {
  grid-area: download; }

@media (min-width: 960px) {
  .tab2 .download-buttons-container {
    -ms-flex-wrap: wrap;
        flex-wrap: wrap; } }

@media (min-width: 960px) {
  .tab3 {
    -ms-grid-columns: 1fr 24px 1fr;
    grid-template-columns: 1fr 1fr;
    -ms-grid-rows: auto 26px 1fr;
    grid-template-rows: auto 1fr;
        grid-template-areas: "description image" "description download";
    grid-row-gap: 26px;
    grid-column-gap: 24px; }
  .tab3 > .area-image {
    -ms-grid-row: 1;
    -ms-grid-row-span: 1;
    -ms-grid-column: 3; }
  .tab3 > .area-description {
    -ms-grid-row: 1;
    -ms-grid-row-span: 3;
    -ms-grid-column: 1; }
  .tab3 > .area-download {
    -ms-grid-row: 3;
    -ms-grid-column: 3; }
  .tab3 > .area-image {
    -ms-grid-row: 1;
    -ms-grid-row-span: 1;
    -ms-grid-column: 3; }
  .tab3 > .area-description {
    -ms-grid-row: 1;
    -ms-grid-row-span: 3;
    -ms-grid-column: 1; }
  .tab3 > .area-download {
    -ms-grid-row: 3;
    -ms-grid-column: 3; } }

@media (max-width: 959px) {
  .tab3 {
    -ms-grid-rows: auto 25px auto 25px auto;
    grid-template-rows: auto auto auto;
        grid-template-areas: "image" "description" "download";
    grid-row-gap: 25px; }
  .tab3 > .area-image {
    -ms-grid-row: 1;
    -ms-grid-row-span: 1;
    -ms-grid-column: 1; }
  .tab3 > .area-description {
    -ms-grid-row: 3;
    -ms-grid-row-span: 1;
    -ms-grid-column: 1; }
  .tab3 > .area-download {
    -ms-grid-row: 5;
    -ms-grid-column: 1; }
  .tab3 > .area-image {
    -ms-grid-row: 1;
    -ms-grid-row-span: 1;
    -ms-grid-column: 1; }
  .tab3 > .area-description {
    -ms-grid-row: 3;
    -ms-grid-row-span: 1;
    -ms-grid-column: 1; }
  .tab3 > .area-download {
    -ms-grid-row: 5;
    -ms-grid-column: 1; } }

.tab3 * {
  white-space: pre-wrap; }

@media (min-width: 960px) {
  .tab3 .description {
    margin-bottom: 40px; } }

@media (max-width: 959px) {
  .tab3 .description {
    margin-bottom: 42px; } }

.tab3 .area-image {
  grid-area: image; }
  .tab3 .area-image > div {
    width: 100%; }
    @media (min-width: 960px) {
      .tab3 .area-image > div {
        background-position: 0% 50%;
        height: 356px; } }
    @media (max-width: 959px) {
      .tab3 .area-image > div {
        height: 204px; } }

.tab3 .area-description {
  grid-area: description;
  font-family: 'Roboto-Light', 'Noto-Light'; }
  @media (min-width: 960px) {
    .tab3 .area-description {
      font-size: 24px;
      line-height: 38px; } }
  @media (max-width: 959px) {
    .tab3 .area-description {
      font-size: 12px; } }

.tab3 .area-download {
  grid-area: download; }

@media (min-width: 960px) {
  .tab1 .area-image {
    -ms-grid-row: 1;
    -ms-grid-column: 3; }
  .tab1 .area-description {
    -ms-grid-row: 1;
    -ms-grid-column: 1; }
  .tab2 .area-image {
    -ms-grid-row: 1;
    -ms-grid-column: 3; }
  .tab2 > .area-image {
    -ms-grid-row: 1;
    -ms-grid-row-span: 3;
    -ms-grid-column: 1; }
  .tab2 .area-description {
    -ms-grid-row: 1;
    -ms-grid-column: 1; }
  .tab2 > .area-description {
    -ms-grid-row: 1;
    -ms-grid-column: 3; }
  .tab2 .area-download {
    -ms-grid-row: 3;
    -ms-grid-column: 3; }
  .tab3 .area-image {
    -ms-grid-row: 1;
    -ms-grid-column: 3; }
  .tab2 > .area-image {
    -ms-grid-row: 1;
    -ms-grid-row-span: 3;
    -ms-grid-column: 1; }
  .tab3 > .area-image {
    -ms-grid-row: 1;
    -ms-grid-row-span: 1;
    -ms-grid-column: 3; }
  .tab3 .area-description {
    -ms-grid-row: 1;
    -ms-grid-column: 1; }
  .tab2 > .area-description {
    -ms-grid-row: 1;
    -ms-grid-column: 3; }
  .tab3 > .area-description {
    -ms-grid-row: 1;
    -ms-grid-row-span: 3;
    -ms-grid-column: 1; }
  .tab3 .area-download {
    -ms-grid-row: 3;
    -ms-grid-column: 3; }
  .tab3 > .area-download {
    -ms-grid-row: 3;
    -ms-grid-column: 3; } }

@media (max-width: 959px) {
  .tab1 .area-image {
    -ms-grid-row: 1;
    -ms-grid-column: 1; }
  .tab1 .area-description {
    -ms-grid-row: 3;
    -ms-grid-column: 1; }
  .tab2 .area-image {
    -ms-grid-row: 1;
    -ms-grid-column: 1; }
  .tab2 > .area-image {
    -ms-grid-row: 1;
    -ms-grid-row-span: 1;
    -ms-grid-column: 1; }
  .tab2 .area-description {
    -ms-grid-row: 3;
    -ms-grid-column: 1; }
  .tab2 > .area-description {
    -ms-grid-row: 3;
    -ms-grid-column: 1; }
  .tab2 > .area-download {
    -ms-grid-row: 5;
    -ms-grid-column: 1; }
  .tab3 .area-image {
    -ms-grid-row: 1;
    -ms-grid-column: 1; }
  .tab2 > .area-image {
    -ms-grid-row: 1;
    -ms-grid-row-span: 1;
    -ms-grid-column: 1; }
  .tab3 > .area-image {
    -ms-grid-row: 1;
    -ms-grid-row-span: 1;
    -ms-grid-column: 1; }
  .tab3 .area-description {
    -ms-grid-row: 3;
    -ms-grid-column: 1; }
  .tab2 > .area-description {
    -ms-grid-row: 3;
    -ms-grid-column: 1; }
  .tab3 > .area-description {
    -ms-grid-row: 3;
    -ms-grid-row-span: 1;
    -ms-grid-column: 1; }
  .tab2 > .area-download {
    -ms-grid-row: 5;
    -ms-grid-column: 1; }
  .tab3 > .area-download {
    -ms-grid-row: 5;
    -ms-grid-column: 1; } }

.download-buttons-container {
  display: -ms-flexbox;
  display: flex; }
  @media (min-width: 960px) {
    .download-buttons-container {
      -ms-flex-direction: row;
          flex-direction: row; } }
  @media (max-width: 959px) {
    .download-buttons-container {
      -ms-flex-direction: column;
          flex-direction: column;
      -ms-flex-align: stretch;
          align-items: stretch;
      padding: 0 10%; } }
  .download-buttons-container button {
    margin: 0; }
    @media (min-width: 960px) {
      .download-buttons-container button {
        margin-bottom: 20px; } }
  @media (min-width: 960px) {
    .download-buttons-container button:not(:last-child) {
      margin-right: 20px; } }
  @media (max-width: 959px) {
    .download-buttons-container button:not(:last-child) {
      margin-bottom: 30px; } }

.download-title {
  color: #4A4A4A;
  font-weight: bold; }
  @media (min-width: 960px) {
    .download-title {
      font-size: 16px;
      line-height: 24px;
      margin-bottom: 36px; } }
  @media (max-width: 959px) {
    .download-title {
      font-size: 12px;
      line-height: 22px;
      margin-bottom: 15px;
      text-align: center; } }

.download {
  color: #0073AE;
  text-transform: none; }
  @media (min-width: 960px) {
    .download {
      font-size: 16px;
      line-height: 19px;
      padding: 12px 20px; } }
  @media (max-width: 959px) {
    .download {
      font-size: 14px;
      line-height: 16px;
      padding: 13px 18px; } }

.banner-title {
  color: #FFFFFF;
  text-align: center; }
  @media (min-width: 960px) {
    .banner-title {
      font-family: 'Roboto-Light', 'Noto-Light';
      font-size: 32px;
      line-height: 48px; } }
  @media (max-width: 959px) {
    .banner-title {
      font-family: 'Roboto-Regular', 'Noto-Regular';
      font-size: 14px;
      line-height: 24px; } }

@media (max-width: 959px) {
  .banner-image {
    -o-object-position: 40%;
       object-position: 40%; } }

.description-image {
  width: 100%; }
  @media (min-width: 960px) {
    .description-image {
      height: 387px; } }
  @media (max-width: 959px) {
    .description-image {
      height: 214px; } }

@media (max-width: 959px) {
  .tabs-header {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: row;
        flex-direction: row;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    -ms-flex-pack: center;
        justify-content: center;
    -webkit-box-shadow: inset 0 -1px 0 0px #CAC8C8;
            box-shadow: inset 0 -1px 0 0px #CAC8C8;
    width: 100%;
    padding: 0 25px; } }

@media (min-width: 960px) {
  .tabs-header {
    padding: 0 132px; } }

.tabs-header .tab {
  -ms-flex-positive: 1;
      flex-grow: 1;
  text-align: center; }

@media (max-width: 959px) {
  .tabs-header .tab:not(:last-child) {
    margin-right: 23px; } }

@media (min-width: 960px) {
  .tab-header {
    font-family: 'Roboto-Regular', 'Noto-Regular';
    color: #0073AE !important;
    margin-bottom: 42px !important; } }

@media (max-width: 959px) {
  .tab-header {
    font-family: 'Roboto-Light', 'Noto-Light';
    color: #0073AE !important;
    font-size: 20px; } }
