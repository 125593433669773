.home-container {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  -ms-flex-align: center;
      align-items: center; }

@media (min-width: 960px) {
  .overlay {
    height: 680px;
    padding-top: 95px; } }

@media (max-width: 959px) {
  .overlay {
    height: 320px; } }

.down-arrow {
  width: 29px;
  height: 18px;
  position: absolute;
  bottom: 20%; }
