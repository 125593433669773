.container {
  width: 100%;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  -ms-flex-align: center;
      align-items: center; }
  .container .description-container {
    width: 100%;
    background-color: #ffffff;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
        flex-direction: column;
    -ms-flex-align: center;
        align-items: center;
    text-align: center; }
    @media (min-width: 960px) {
      .container .description-container {
        padding-top: 59px;
        padding-bottom: 90px; } }
    @media (max-width: 959px) {
      .container .description-container {
        padding-top: 44px;
        padding-bottom: 40px; } }
    .container .description-container .description-content .description-title {
      color: #0073AE;
      text-align: center; }
      @media (min-width: 960px) {
        .container .description-container .description-content .description-title {
          font-family: 'Roboto-Regular', 'Noto-Regular';
          font-size: 48px;
          line-height: 56px;
          margin-bottom: 43px; } }
      @media (max-width: 959px) {
        .container .description-container .description-content .description-title {
          font-family: 'Roboto-Light', 'Noto-Light';
          font-size: 20px;
          line-height: 22px;
          margin-bottom: 45px;
          padding: 0 16px; } }
    .container .description-container .description-content .description-subtitle {
      text-align: center; }
      @media (min-width: 960px) {
        .container .description-container .description-content .description-subtitle {
          font-family: 'Roboto-Regular', 'Noto-Regular';
          color: #000000;
          font-size: 22px;
          line-height: 29px;
          margin-bottom: 71px; } }
      @media (max-width: 959px) {
        .container .description-container .description-content .description-subtitle {
          font-family: 'Roboto-Light', 'Noto-Light';
          color: #4A4A4A;
          font-size: 14px;
          line-height: 22px;
          margin-bottom: 30px;
          padding: 0 20%; } }
    .container .description-container .description-content .columns {
      display: -ms-grid;
      display: grid; }
      @media (min-width: 960px) {
        .container .description-container .description-content .columns {
          -ms-grid-columns: 1fr 24px 1fr 24px 1fr;
          grid-template-columns: repeat(3, 1fr);
          -ms-grid-rows: auto;
          grid-template-rows: auto;
          grid-column-gap: 24px; }
        .container .description-container .description-content .columns > *:nth-child(1) {
    -ms-grid-row: 1;
    -ms-grid-column: 1; }
        .container .description-container .description-content .columns > *:nth-child(2) {
    -ms-grid-row: 1;
    -ms-grid-column: 3; }
        .container .description-container .description-content .columns > *:nth-child(3) {
    -ms-grid-row: 1;
    -ms-grid-column: 5; } }
      @media (max-width: 959px) {
        .container .description-container .description-content .columns {
          display: none; } }
      .container .description-container .description-content .columns .column-content {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-direction: column;
            flex-direction: column;
        -ms-flex-align: stretch;
            align-items: stretch; }
        .container .description-container .description-content .columns .column-content .image-container {
          position: relative; }
          .container .description-container .description-content .columns .column-content .image-container > img {
            width: 100%;
            height: 289px;
            -o-object-fit: cover;
               object-fit: cover; }
          .container .description-container .description-content .columns .column-content .image-container .description-text {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            display: -ms-flexbox;
            display: flex;
            -ms-flex-pack: center;
                justify-content: center;
            -ms-flex-align: center;
                align-items: center;
            white-space: pre-wrap;
            color: #FFFFFF; }
            @media (min-width: 960px) {
              .container .description-container .description-content .columns .column-content .image-container .description-text {
                font-size: 24px;
                line-height: 28px; } }
            @media (max-width: 959px) {
              .container .description-container .description-content .columns .column-content .image-container .description-text {
                font-size: 18px;
                line-height: 21px; } }
        .container .description-container .description-content .columns .column-content .text-container {
          background-color: #FFFFFF;
          color: #000000;
          text-align: left; }
          @media (min-width: 960px) {
            .container .description-container .description-content .columns .column-content .text-container {
              font-family: 'Roboto-Light', 'Noto-Light';
              font-size: 18px;
              line-height: 32px;
              min-height: 324px;
              padding: 36px 18px;
              -ms-flex-positive: 1;
                  flex-grow: 1; } }
          @media (max-width: 959px) {
            .container .description-container .description-content .columns .column-content .text-container {
              text-align: center;
              font-family: 'Roboto-Regular', 'Noto-Regular';
              font-size: 14px;
              line-height: 20px;
              padding: 32px 20px; } }
  .container .description {
    font-family: 'Roboto-Light', 'Noto-Light';
    margin-bottom: 90px;
    padding: 0 8.33%;
    text-align: left; }
    @media (min-width: 960px) {
      .container .description {
        font-size: 22px;
        margin-top: 44px; } }
    @media (max-width: 959px) {
      .container .description {
        font-size: 12px; } }
  .container .description-list {
    font-family: 'Roboto-Light', 'Noto-Light';
    padding: 0 8.33%;
    text-align: left; }
    @media (min-width: 960px) {
      .container .description-list {
        list-style: circle;
        padding-left: calc(8.33% + 30px);
        font-size: 22px; } }
    @media (max-width: 959px) {
      .container .description-list {
        list-style: none;
        font-size: 12px;
        margin-bottom: 0; } }
    @media (min-width: 960px) {
      .container .description-list li {
        padding-bottom: 50px;
        padding-left: 30px; } }
    @media (max-width: 959px) {
      .container .description-list li {
        padding-bottom: 30px; } }
