.app-container {
  max-width: 100%;
  height: 100%;
  margin: auto; }
  @media (min-width: 960px) {
    .app-container {
      padding-top: 140px; } }
  @media (max-width: 959px) {
    .app-container {
      padding-top: 68px; } }

body {
  background-color: white !important;
  font-family: "Roboto-Regular", "Noto-Regular"; }
