.container {
  width: 100%;
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center; }
  .container .image {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
       object-fit: cover; }
  .container .inner-container {
    width: 100%;
    height: 100%;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
        flex-direction: column;
    -ms-flex-align: center;
        align-items: center;
    position: relative; }
    @media (min-width: 960px) {
      .container .inner-container {
        margin: 80px 0; } }
    @media (max-width: 959px) {
      .container .inner-container {
        margin: 45px 0 54px; } }
  .container .text {
    color: #FFFFFF;
    text-align: center;
    width: 80%;
    white-space: pre-wrap; }
  @media (min-width: 960px) {
    .container .margin {
      margin-bottom: 40px; } }
  @media (max-width: 959px) {
    .container .margin {
      margin-bottom: 30px; } }
  @media (min-width: 960px) {
    .container .button {
      min-width: 125px; } }
  @media (max-width: 959px) {
    .container .button {
      width: 104px;
      padding-left: 0px;
      padding-right: 0px; } }
